import { Icon, Label, Overlay } from '@fluentui/react';
import React, { DragEvent, useState } from 'react';
export class DragAndDropProps {
    onFilesDropped!: (files: { [key: string]: string; }) => void;
}
const DragAndDrop: React.FunctionComponent<React.PropsWithChildren<DragAndDropProps>> = ({ onFilesDropped, children }) => {
    const [mouseOverCount, setMouseOverCount] = useState<number>(0);
    const defaultEventHandler = (e: DragEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (event: DragEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setMouseOverCount(mouseOverCount-1);
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            let filesDictionary: { [key: string]: string } = {};
            let filePromises: Promise<any>[] = [];
            for (let i = 0; i < files.length; i++) {
                const file = files.item(i);
                if (file) {
                    filePromises.push(file.arrayBuffer().then((content: ArrayBuffer) => {
                        const fileName = file?.name;
                        const fileBase64 = arrayBufferToBase64(content);
                        filesDictionary[fileName] = fileBase64;
                    }));
                }
            }
            Promise.all(filePromises).then(() => {
                onFilesDropped(filesDictionary);
            })
        }

    };


    function arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
        let buffer = new Uint8Array(arrayBuffer);
        let binary = '';
        const len = buffer.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(buffer[i]);
        }
        return window.btoa(binary);
    }

    return (
        <div className={'drag-drop-zone'} style={(mouseOverCount>0) ? {borderColor: "#000055", borderWidth: "1px"} : undefined}
            onDragOver={defaultEventHandler}
            onDragEnter={(e:any)=>{console.log("dragEnter", e); setMouseOverCount(mouseOverCount + 1)}}
            onDragLeave={(e:any)=>{console.log("dragLeave", e); setMouseOverCount(mouseOverCount - 1)}}
            children={(mouseOverCount > 0 ? [children, <Overlay
                onDrop={handleDrop} style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}><Icon style={{fontSize: "2em"}} iconName={"CloudUpload"} /><Label style={{color: "#555555"}}>Drop files here to scan them...</Label></Overlay>] : children) || [<p>Drag and drop files to upload...</p>]}
        />
    );
}
export default DragAndDrop;