import { useEffect, useState } from "react";
import styles from "./PageBody.module.scss";
import { CheckboxVisibility, DetailsList, Dialog, DialogFooter, IColumn, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import RFQPage from "./rfqPage";
import useFetchWithMsal from "../../services/msalWebClient";
import { loginRequest } from "../../services/authConfig";
import DragAndDrop from "./DragDropTarget";

const PageBody : React.FunctionComponent<{}> = ()=>{
    const { execute, data } = useFetchWithMsal(loginRequest.scopes);
    const { execute: queueExecute } = useFetchWithMsal(loginRequest.scopes);
    const [isUploading, setIsUploading] = useState<boolean>();
    const [ uploadFailed, setUploadFailed] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>();
    const [orderBy, setOrderBy] = useState<string>();

    useEffect(()=>{
        execute("GET", "List");
    }, [execute]);
    
    useEffect(()=>{
        if(selectedItem){
            let newSelectedItem = data.find((i: any)=>i.id === selectedItem.id);
            if(selectedItem !== newSelectedItem){
                setSelectedItem(newSelectedItem);
            }
            
        }
    }, [data, selectedItem]);

    const columns = () : IColumn[] => {
        let cols : IColumn[] = [
            {
                key: "Status",
                minWidth: 100,
                maxWidth: 100,
                name: "Status",
                fieldName: "status",
                flexGrow: 0,
                onColumnClick: ()=>{
                    setOrderBy("status")
                }
            },
            {
                key: "RFQ Number",
                minWidth: 100,
                maxWidth: 200,
                flexGrow: 1,
                name: "RFQ Nummer",
                onRender: (item)=>{
                    return item.rfqInformation.rfqNumber;
                },
                onColumnClick: ()=>{
                    setOrderBy("rfqNumber")
                }
            },
            {
                key: "Customer",
                minWidth: 200,
                maxWidth: 500,
                flexGrow: 1,
                name: "Client name",
                onRender: (item)=>{
                    return item.clientInformation.name;
                },
                onColumnClick: ()=>{
                    setOrderBy("clientInformation.name")
                }
            }, 
            {
                key: "AssignedTo",
                minWidth: 200,
                flexGrow: 1,
                name: "Assigned to",
                onRender: (item)=>{
                    return item.assignedTo?.displayName;
                },
                onColumnClick: ()=>{
                    setOrderBy("assignedTo.displayName")
                }
            }];
        return cols;
    }

    const getItems = ()=>{
        if(data){
            return orderBy ? data.sort((i1: any, i2: any)=>(getValue(i1, orderBy) > getValue(i2, orderBy) ? 1 : -1)) : data;
        }
        return [];
    }

    const getValue = (item: any, path: string) => {
        var parts = path.split(".");
        var value = item;
        parts.forEach((p)=>{if(value){value = value[p]}});
        return value ?? "";
    }

    const openRFQ = (item: any) =>{
        setSelectedItem(item);
    }

    const uploadFile = (files: {[key:string]:string}) => {
        setIsUploading(true);
        queueExecute("POST", "Queue", {files}).then((result)=>{
            if((result?.status ?? 200) < 300) {
                setIsUploading(false);
                execute("GET", "List").then(()=>{});
            }
            else{
                setUploadFailed(true);
            }
                
        }).catch(()=>{
            setUploadFailed(true);
            
        });
    }

    const handleCloseItem = (reload: boolean)=>{
        setSelectedItem(undefined);
        if(reload){
            execute("GET", "List");
        }
    }

    return <div className={styles.body}>
        
        {selectedItem ?
        <RFQPage item={selectedItem} onClose={handleCloseItem} reload={()=>execute("GET", "List")} /> :
        
        <DragAndDrop onFilesDropped={uploadFile}>
            <div className={styles.rfqList}>
                <DetailsList
                    items={getItems()} 
                    checkboxVisibility={CheckboxVisibility.hidden}
                    columns={columns()} 
                    onRenderRow={(props, defaultRender) => <div onClick={()=>openRFQ(props?.item)}>{defaultRender!(props)}</div> } 
                />
            </div>
        </DragAndDrop>
        }
        <Dialog hidden={!isUploading} dialogContentProps={{showCloseButton: uploadFailed, title: "Upload file", subText:uploadFailed?"Document could not be scanned.":undefined}}>
            {uploadFailed ? 
                    <DialogFooter>
                        <PrimaryButton onClick={()=>{
                            setIsUploading(false);
                            setUploadFailed(false);
                            }} 
                        >Close</PrimaryButton>
                    </DialogFooter>
            :
                <Spinner size={SpinnerSize.large} label={"Scanning file..."} />
            }
        </Dialog>
    </div>;
}

export default PageBody;